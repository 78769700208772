(function ($) {
  $(window).on("load", function () {});
  $(document).ready(function () {
    new WOW().init();
    $('#nav-icon1').click(function () {
      $(this).toggleClass('open');
      $('body , html').toggleClass('overFlow');
      $('.main-menu').fadeToggle();
    });
    $('.faq_head').click(function () {
      $(this).parents('.faq_list').addClass('show');
      $(this).parents('.faq_list').siblings('.faq_list').removeClass('show');
      $(this).parents('.faq_list').find('.faq_details').slideToggle();
      $(this).parents('.faq_list').siblings('.faq_list').find('.faq_details').slideUp();
    });
    $('.slider_move').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      arrows: true,
      dots: false,
      autoplay: false,
      centerMode: true,
      centerPadding: '200px',
      speed: 400,
      autoplaySpeed: 4000,
      asNavFor: '.thumb_slider',
      responsive: [{
        breakpoint: 1500,
        settings: {
          centerPadding: '140px'
        }
      }, {
        breakpoint: 1200,
        settings: {
          centerPadding: '0'
        }
      }]
    });
    $('.car_txt_slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.slider_move'
    });
    $('.thumb_slider').slick({
      slidesToShow: 9,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      focusOnSelect: true,
      asNavFor: '.slider_move',
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 7
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 575,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }]
    });
    $('.ad_slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      infinite: true,
      fade: true
    });
    $('.menu-item-has-children').append('<span class="menu-arrow"></span>');
    $('.menu-arrow').click(function (en) {
      en.preventDefault();
      $(this).parents('li').siblings('li').find('.menu-arrow').removeClass('active');
      $(this).toggleClass('active');
      $(this).parents('li').find('.sub-menu').slideToggle();
      $(this).parents('li').siblings('li').find('.sub-menu').slideUp();
    });
    $('.heart_fav').click(function (en) {
      $(this).toggleClass('fvt');
    });
    $('.link_sidebar li a').click(function (en) {
      en.preventDefault();
      $(this).parents('li').toggleClass('active');
      $($(this).attr('href')).siblings('.deatil_wrap').removeClass('show');
      $($(this).attr('href')).addClass('show');
    });
    $('.filter_Head').click(function (en) {
      en.preventDefault();
      $(this).parents('li').siblings('li').removeClass('active');
      $(this).parents('li').addClass('active');
      $(this).parents('li').find('.filter_details').slideToggle();
    });
    $('.steps_left ul li .stepsName').click(function (en) {
      en.preventDefault();
      $(this).parents('li').siblings('li').removeClass('active');
      $(this).parents('li').addClass('active');
    });
    $('input[name="upload-img"]').on('change', function () {
      readURL(this, $('.file-wrapper')); //Change the image
    });

    $('.close-btn').on('click', function () {
      //Unset the image
      let file = $('input[name="upload-img"]');
      $('.file-wrapper').css('background-image', 'unset');
      $('.file-wrapper').removeClass('file-set');
      file.replaceWith(file = file.clone(true));
    });
    if ($(window).width() > 767) {
      $(".brands_wrap li:hidden").slice(0, 10).show();
    } else {
      $(".brands_wrap li:hidden").slice(0, 5).show();
    }
    $("#seeMore01").click(function (e) {
      e.preventDefault();
      $(".brands_wrap li:hidden").slice(0, 5).fadeIn("slow");
      if ($(".brands_wrap li:hidden").length == 0) {
        $("#seeMore01").fadeOut("slow");
      }
    });
    $(".acheter_main .ad_wrap .ad_item:hidden").slice(0, 5).show();
    $("#seeMore").click(function (e) {
      e.preventDefault();
      $(".acheter_main .ad_wrap .ad_item:hidden").slice(0, 3).fadeIn("slow");
      $('.ad_slider .slick-next').trigger('click');
      if ($(".acheter_main .ad_wrap .ad_item:hidden").length == 0) {
        $("#seeMore").fadeOut("slow");
      }
    });

    //FILE
    function readURL(input, obj) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          obj.css('background-image', 'url(' + e.target.result + ')');
          obj.addClass('file-set');
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    ;
  });

  //parallax scroll
  $(window).scroll(function () {
    if ($(this).scrollTop() > 60) {
      $('.header').addClass("sticky");
    } else {
      $('.header').removeClass("sticky");
    }
  });
  $(window).resize(function () {});
  const inputs = document.querySelectorAll('.inputfile');
  for (let input of inputs) {
    const label = input.nextElementSibling;
    const labelVal = label.innerHTML;
    input.addEventListener('change', e => {
      let fileName = '';
      if (input.files && input.files.length > 1) {
        fileName = (input.getAttribute('data-multiple-caption') || '').replace('{count}', input.files.length);
      } else {
        fileName = e.target.value.split('\\').pop();
      }
      if (fileName) {
        label.querySelector('span').innerHTML = fileName;
      } else {
        label.innerHTML = labelVal;
      }
    });
  }
  const inputs22 = document.querySelectorAll('.inputfile2');
  for (let input22 of inputs22) {
    const label22 = input22.nextElementSibling;
    const labelVal22 = label22.innerHTML;
    input22.addEventListener('change', e => {
      let fileName22 = '';
      if (input22.files && input22.files.length > 1) {
        fileName22 = (input22.getAttribute('data-multiple-caption') || '').replace('{count}', input22.files.length);
      } else {
        fileName22 = e.target.value.split('\\').pop();
      }
      if (fileName22) {
        label22.querySelector('span').innerHTML = fileName22;
      } else {
        label22.innerHTML = labelVal22;
      }
    });
  }
  const inputs33 = document.querySelectorAll('.inputfile3');
  for (let input33 of inputs33) {
    const label33 = input33.nextElementSibling;
    const labelVal33 = label33.innerHTML;
    input33.addEventListener('change', e => {
      let fileName33 = '';
      if (input33.files && input33.files.length > 1) {
        fileName33 = (input33.getAttribute('data-multiple-caption') || '').replace('{count}', input33.files.length);
      } else {
        fileName33 = e.target.value.split('\\').pop();
      }
      if (fileName33) {
        label33.querySelector('span').innerHTML = fileName33;
      } else {
        label33.innerHTML = labelVal33;
      }
    });
  }
})(jQuery);